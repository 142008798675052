.customer_model_form {
  overflow: auto;
  max-height: 400px;
}

.image_customer_container_admin {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.company_logo {
  width: 270px;
  height: 100px;
  border: solid 0.4px rgb(237, 237, 237);
  border-radius: 0%;
  align-items: center;
  object-fit: contain;
  display: flex;
  justify-content: center;
}
