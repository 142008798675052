* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

body {
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
}
.active {
  background-color: #ddd1da;
}
:root {
  --primaryColor: #801769;
  --secondaryColor: #e8e8e8;
  --backgroundColor: #f3f3f3;
  --border: 0.4px solid #d1dddb;
  --sidebar_bg: #092620;
  --titlefont: 14px;
  --text_color: #002e25;
  --background_container: #ffffff;
  --background: #f1fcfa;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  font-weight: 400;
}

/* Hide default number input arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Hide the default eye icon in password input fields */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

/* Hide the default eye icon in password input in firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.formfields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Main Page css */

.mainPage {
  position: relative;
  border-left: 5px solid var(--backgroundColor);
  width: 100%;
  padding: 0px 20px 20px;
  min-height: calc(100vh - (60px + 65px));
}

.multi_type_page {
  min-height: calc(100vh - (60px + 65px + 57px)) !important;
}

.mainPageTop {
  background: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 1px,
    rgba(0, 0, 0, 0.01) 1px 1px 1px 0px;
}

.mainPageTop .mainPageTopRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 10px;
}

/* TABLE CSS */
.td-actions {
  display: flex;
  align-items: center;
  padding-right: 10px;
  max-width: 91px;
  gap: 5px;
}
.tableWrpr {
  overflow: auto;
  max-height: 500px;
  margin-top: 4px;
}

.table-container {
  background-color: #ffffff;
  /* border: 0.4px solid #d1dddb; */
  border-collapse: collapse;
  margin-top: 0px;
  width: 100%;
}

.th {
  font-size: 10px;
  font-weight: 800;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text_color);
  padding-left: 20px;
}

.tr-container {
  height: 38px;
  border-bottom: var(--border);
}

.td {
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #002e25;
  padding-left: 20px;
  height: 38px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td-index {
  width: 8%;
}
.tp_form .customInputWrpr {
  margin-bottom: 0;
}
.td_header_color {
  position: sticky;
  top: 0%;
  height: 38px;
  background-color: #feeef1;
}
.td_header_color .th {
  color: #801769;
}
.totalCount_row {
  background-color: var(--backgroundColor);
}

.totalCount_row .table_bg {
  max-width: 200px;
  padding: 0;
  background-color: var(--backgroundColor);
}
.totalCount_row .table_bg .customInputWrpr {
  margin-bottom: 0;
}

.totalCount_row
  .table_bg
  .customInputWrpr
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 30px;
}
.totalCount_row
  .table_bg
  .customInputWrpr
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 30px;
}

.totalCount_row .table_bg .customInputWrpr .labelInput .css-1v4ccyo {
  height: 30px !important;
}

.content {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.loadingTd {
  padding: 10px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
/* POPUP FORM */

.popFormBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  border-top: 1px solid var(--secondaryColor);
  padding-top: 20px;
}

/* ACTION BUTTON */
.icon_opacity {
  opacity: 1 !important;
}
.totalcount {
  color: black !important;
  opacity: 1 !important;
  font-weight: 500;
}
.totalcount_heading {
  color: black !important;
  opacity: 1 !important;
  font-weight: 500;
}
.view {
  display: flex;
  align-items: center;
  gap: 10px;
}
.view button .MuiSvgIcon-root {
  opacity: 1 !important;
  color: black !important;
  font-size: 20px;
}

.view button {
  border: none;
  background-color: var(--secondaryColor);
  border-radius: 4px;
  padding: 4px 6px;
  cursor: pointer;
}
.view p {
  border: none;
  background-color: var(--secondaryColor);
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
}

/* FORMS */
.mainForm {
  padding: 40px;
  /* background-color: #fff; */
}

.mainForm .form {
  min-height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainForm .form .form_fields {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  /* row-gap: 15px; */
}

.mainForm .form_field label {
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 17px;
  color: #171717;
  opacity: 0.8;
  font-family: "lexend" !important;
}

.mainForm .formBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid var(--secondaryColor);
  padding-top: 20px;
}
.col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}
.black-ine-container {
  margin: 10px 0 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  height: 1px;
  width: 100%;
}
.col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
/* DESKTOP */
@media screen and (max-width: 1024px) {
  .col_2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
  }
  .col_4 {
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  .mainForm .form .form_fields {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* TABLET */
@media screen and (max-width: 768px) {
  .col_4 {
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
  }
  .mainForm .form .form_fields {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadingTd {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}

/* MOBILE */
@media screen and (max-width: 576px) {
  .mainPage {
    padding: 0px 10px 20px;
  }
  .col_4 {
    row-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
  .mainPageTop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .mainPageTop .mainPageTopRight {
    gap: 10px;
  }
  .mainForm .form .form_fields {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0px;
  }

  .mainForm {
    padding: 25px;
  }

  .mainForm .form_field label {
    font-size: 18px;
  }
}

.bottomBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid var(--secondaryColor);
  padding-top: 20px;
}

/* CIRCULAR LOADER CSS */
.circular_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.circular_loader_container .circular_loader {
  color: var(--primaryColor);
}

/* AUTO COMPLETE */

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1xnbq41-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  width: 100%;
  padding: 9px 0;
}

.autocomplete_label {
  margin-bottom: 2px !important;
  color: #333333 !important;
  opacity: 0.8;
  font-size: 17px !important;
  font-weight: 300 !important;
  letter-spacing: 0.3px;
}

/* For live */
.css-segi59 {
  height: 45px !important;
}

.errormessage {
  font-size: 10px;
  color: red;
  margin: 0;
  bottom: -17px;
}

/* Tabs */
.empViewBtm {
  margin-top: 10px;
  border-bottom: #eeeeeec7 1px solid;
  padding: 0px 20px 10px;
}
.empViewBtm button {
  margin-top: 5px;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 25px 6px 25px;
  color: black;
  text-align: center;
  /* transition: background-color 0.9s ease-in-out;
  transition: border-bottom 0.4s ease-in-out; */
}

.empViewBtm .active {
  border-bottom: 3px solid var(--primaryColor);
  background-color: whitesmoke;
  color: var(--primaryColor) !important;
  transition: background-color 0.4s ease-in-out;
}

.empViewBtm .de_active {
  border: none;
}

.proile_input {
  border-radius: 3px;
  padding: 6px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.autocompleteInput {
  margin-top: 5px;
}
.autocompleteInput .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 45px !important;
}

@media screen and (max-width: 998px) {
  .empViewBtm button {
    padding: 0px 15px 8px 15px;
  }
}

.filter_pagi {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.page_input .dropdown-container {
  margin-bottom: 0px;
}

.page_input .dropdown-container .dropdown-input {
  min-width: 50px;
  width: auto;
  height: 28px;
}
.sort_by_filter p {
  font-size: 13px;
  font-weight: 400;
}
