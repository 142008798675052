.button_small button {
  padding: 4px;
  height: 15px !important;
}
.button_small button svg {
  font-size: 9px !important;
}
.button_table button {
  padding: 4px;
  height: 24px !important;
}
