.dash_chart {
  width: 100%;
  min-height: 110px;
  height: auto;
  background-color: #feeef1;
  color: #92031e;
  padding: 15px;
}
.para_dash_chart1 {
  color: #272727;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 3px 0;
  cursor: pointer;
  padding: 1px 8px;
  transition: all 0.2s ease-in-out;
}
.para_dash_chart2 {
  font-size: 29px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  margin: 17px 0 3px 0;
  cursor: pointer;
  padding: 1px 8px;
  transition: all 0.2s ease-in-out;
}
