.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
}

.dropdown-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 7px;
  margin-top: 10px;
}

@media screen and (max-width: 856px) {
  .dropdown-container {
    margin-bottom: 0px;
  }

  .dropdown-label {
    margin-top: 14px;
  }
}

.dropdown-input {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #a9a9a9;
  font-weight: 600;
  padding-left: 15px;
  -webkit-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z'  fill='black'/></g></svg>")
    no-repeat;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  outline: none;
}
