.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.input-container-checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: relative;
}

.input-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000000;
  margin-bottom: 7px;
  margin-top: 16px;
  text-wrap: wrap;
}

.input-label-checkbox {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000000;
  margin-left: 8px;
}

.input-field {
  height: 36px;
  border-radius: 4px;
  border: 1px solid #a9a9a9;
  padding-left: 15px;
  font-weight: 600;
  outline: none;
  padding-right: 8px;
  box-sizing: border-box;
  background-color: transparent;
}

.input-field-error-msg {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -30px;
  margin-bottom: 14px;
}

.password-input-container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  position: relative;
}

.password-show-icon-span {
  position: absolute;
  right: 10px;
  color: grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.password-show-icon-span:hover {
  /*transform: scale(1.1);*/
}
.percentage-color {
  color: #262626;
  font-weight: 500;
}

.input-field-password {
  width: 100%;
}

.new-quote-customer-input-attachment-heading-con {
  display: flex;
  justify-content: space-between;
}

.input-field-color {
  width: 100%;
  height: 36px;
  padding-left: 4px;
  padding-right: 4px;
}

.bounce {
  outline: 0;
  border: 1px solid red !important;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
