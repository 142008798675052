.SettingsItemList_items_container {
  width: 250px;
  background-color: #ffffff;
  height: auto;
  border: solid #d1dddb 0.4px;
  border-radius: 4px;
}

.SettingsItemList_title {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.SettingsItemList_items {
  display: flex;
  border-top: var(--border);
  justify-content: space-between;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}
.SettingsItemList_items:hover {
  /* transform: scale(1.01); */
  background-color: #ece4eb;
  /* border: solid 0.4px #f4f4f4; */
}
.inside_item_name {
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
