.btnWrpr button {
  display: flex;
  align-items: center;
  gap: 5px;
}
.btnWrpr.primButton button {
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;

  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
  border: 0px;
  outline: none;
}
.py-10 button {
  padding: 0 10px;
}
.btnWrpr.btn_red button {
  background-color: #ce2b2b;
  /* width: 360px; */
  /* padding: 3px 10px 4px 10px; */
  height: 36px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
  border: 0px;
  outline: none;
}

.btnWrpr.small button {
  height: 22px;
}

.btnWrpr.large button {
  height: 36px;
}
.btnWrpr.medium button {
  /* width: 100%; */
  height: 27px;
}
.w-90 button {
  width: 100px;
}
.btnWrpr.secButton button {
  color: #181818 !important;
  background: transparent;
  border: 0px solid #181818;
  background: #ededed;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: #002e25;
  transition: all 0.1s ease-in-out;
}
.btnWrpr.secButton button:hover {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 4px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
  /* transform: scale(1.05); */
}
.btnWrpr button svg {
  font-size: 15px;
  /* color: #696969; */
}

@media only screen and (max-width: 576px) {
  .btnWrpr button {
    height: 30px;
    /* padding: 7px 10px; */
    font-size: 0.8rem;
  }
  .btnWrpr button.small {
    width: 100%;
    height: 25px;
  }
}

.btnWrpr.terinary button {
  background-color: #e0fffc;
  color: var(--pr);
  border: solid var(--pr) 0.4px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  margin-right: 7px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  min-width: 84px;
}
.btnWrpr button .modal-add-role-btn {
  cursor: pointer;
  background-color: var(--pr);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #ffffff;
  border: 0px;
  padding: 8px;
  align-self: flex-end;
  margin-top: 10px;
  min-width: 107px;
}
.btnWrpr button .approval_inside_close_button {
  cursor: pointer;
  background-color: white;
  color: black;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  border: var(--border);
  padding: 7px;
  align-self: flex-end;
  margin-top: 10px;
  width: 107px;
}
.popup_button button {
  min-width: 120px;
  transition: none !important;
}
.popup_button button:hover {
  box-shadow: none !important;
  transform: none;
}
