.header-container {
  padding: 18px 0px 18px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  /* box-shadow: 0px 0.5px 0px 0px #00000040; */
  /* border-bottom: var(--border); */
}

.header-ham-search-container {
  display: flex;
  align-items: center;
}

.header-hamburger-icon {
  display: none !important;
}
.logout_popup_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.logout_popup_container h6 {
  font-size: 14px;
  font-weight: 500;
}
.logout_popup_buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.blink {
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
.blink svg {
  font-size: 35px;
}
.MuiBox-root:focus-visible {
  outline: none !important;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@media screen and (max-width: 740px) {
  .header-hamburger-icon {
    display: block !important;
    height: 22px;
    width: 20px;
    color: #787878;
    margin-right: 10px;
  }
}

.search-input-container {
  display: flex;
  align-items: center;
  background: #edf8f6;
  padding: 0px 15px 0px 15px;
  border-radius: 4px;
}

.search-input-field:focus #search-input-container {
  border: #002e25 solid 0.4px;
  border-radius: 10px;
}

.search-input-field {
  width: 255px;
  height: 34px;
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
  outline: none;
}

@media screen and (max-width: 992px) {
  .search-input-field {
    width: 100%;
  }
}

.search-input-icon {
  height: 22px;
  width: 20px;
  color: #787878;
}

.search-input-btn {
  height: 22px;
  width: 20px;
  background-color: transparent;
  border: 0px;
  outline: none;
}

.header-profile-notification-container {
  display: flex;
  /* gap: 10px; */
  align-items: center;
}

.line-container {
  border-left: 1px solid #00000026;
  height: 34px;
  margin-right: 14px;
}

.header-name-para-container {
  margin-right: 10px;
  width: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header-para1 {
  margin: 0px;

  font-size: 9px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #9d9d9d;
  text-align: left;
}
.header-para1 span {
  margin: 0px;

  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #002e25;
  text-align: left;
}
.header-para2 {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: #002e25;
  text-align: left;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
}
.user_profile {
  object-fit: cover;
}
.header-para2:hover {
  /*transform: scale(1.1);*/
}
.profile-avatar {
  color: #d5d5d5;
  margin-right: 0px;
}

.notification-icon {
  margin-right: 16px;
}

.header-notification-icon-container {
  position: relative;
}

.notification-count-container {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #009e90;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 15px;
}

.notification-count-para {
  font-size: 7px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  color: #ffffff;
}

.header-name-avatar-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.green {
  color: green;
}
.red {
  color: red;
}
.header-logout-container {
  position: absolute;
  right: 0px;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.header-logout-btn {
  background-color: #009e90;
  padding: 8px;
  border: 0px;
  border-radius: 3px;
  font-size: 11px;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
}

.menu-item-md {
  display: none;
}

.menu-link {
  text-decoration: none;
  color: #696d6e !important;
}
.profile-img-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-img-container img {
  transition: all 0.2s ease-in-out;
}
.profile-img-container img:hover {
  /*transform: scale(1.1);*/
}
.header-menu-container {
  width: 100px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #a9a9a926;
  padding: 8px 0px;
  box-shadow: 0px 0px 15px 0px #0000001a;
}

.header-menu-para {
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #002e25;
  margin: 3px 0;
  cursor: pointer;
  padding: 1px 8px;
  transition: all 0.2s ease-in-out;
}
.header-menu-para:hover {
  background-color: #dddddd;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
.jobs-table-checkout-btn p {
  margin: 0;
}
