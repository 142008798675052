.componet_title_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.componet-title {
  font-size: 10px;
  text-transform: uppercase;
  padding: 0px;
  margin: 0 0 0 0px;
  font-weight: 700;
  color: var(--text_color);
}

.componet-title-btn {
  border: 0.4px solid #d1dddb;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: #002e25;
  height: 24px;
  transition-property: box-shadow;
}
.componet-title-btn:hover {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 4px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}
