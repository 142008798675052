.CustomMenu-popup {
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.CustomMenu-popup::before {
  /* content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: -140%;
  right: 30%;
  z-index: 4;
  transform: rotate(50deg);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
    /* top: 10px;
    left: -10px; */
    /* content: "";
    position: absolute;
    z-index: 2;
  top: -92%;
  right: 29%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 16px solid white;
  background-color: transparent; */
}
.CustomMenu-inner {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-width: 133px;
  /* max-height: 70%; */
  /* height: 30vh; */
  background-color: white;
  text-wrap: wrap;
  /* padding: 15px; */
  z-index: 1;
}
