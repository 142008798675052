.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-field-password {
  height: 36px;
  border-radius: 4px;
  font-weight: 600;
  outline: none;
}
.input-normal-field-password {
  border: 1px solid #a9a9a9 !important;
}

.react-tel-input .form-control {
  width: 100%;
  border-radius: 3px;
}
/* .react-tel-input .bounce_phone .form-control.invalid-number {
  border: 1px solid red !important;
} */
.react-tel-input .form-control.invalid-number {
  border: 1px solid #a9a9a9 !important;
  background-color: #fff !important;
}
.react-tel-input .flag-dropdown.invalid-number {
  border-color: #787878 !important;
}
.input-field-error-msg {
  color: red;
  font-size: 12px;
}

.bounce_phone input.form-control.invalid-number {
  outline: 0;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  border: 1px solid red !important;
}
.bounce_phone input {
  outline: 0;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  border: 1px solid red !important;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
