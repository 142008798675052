.Layout {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  overflow-x: hidden;
}

.Layout_main {
  width: 100%;
  overflow: auto;
}

.Layout_outlet {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.Layout_header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 998;
}
