.myprofile_container {
  padding: 15px;
  display: flex;
  gap: 20px;
}
.setting_left {
}
.setting_right {
  width: 100%;
}
.passoword-button {
  display: flex;
  justify-content: flex-end;
}
.customer_file_upload {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.passoword-input {
  max-width: 400px;
}

.avatar_customer {
  width: 100px;
  height: 100px;
  border: solid 0.4px rgb(237, 237, 237);
  border-radius: 50%;
  align-items: center;
  object-fit: cover;
  display: flex;
  justify-content: center;
}
.image_customer_container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.customer_upload_button {
  padding: 5px;
  border-radius: 10px;
  background-color: var(--primaryColor);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.07em;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
.profile-avatar {
  color: #d5d5d5;
  /* margin-right: 8px; */
}

.col_auto_3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  column-gap: 20px;
}

@media screen and (max-width: 1000px) {
  .col_4 {
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

.mt_20 {
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  .col_2 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }

  .col_3 {
    grid-template-columns: repeat(1, 1fr);
  }

  .col_4 {
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .col_auto_3 {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
