.sidebar-container {
  background-color: #1b0015;
  max-width: 230px;
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-header-container {
  display: block !important;
}
.sidebar-header-container:focus-visible {
  outline: none !important;
}

.sidebar-logo-img {
  height: 70px;
  width: 100%;
  display: flex;
  background-color: #1b0015;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}
.sidebar-logo-img img {
  /* height: 40px;
  width: 140px;
  padding: 22px;
  margin: auto;
  object-fit: contain; */
  /* height: 30px; */
  /* margin: auto;
   */
  padding: 20px 10px;
  width: 85%;
  object-fit: contain;
}
.sidebar-logo-img img:hover {
  /* transform: scale(1.05); */
}
.sidebar-menu-container::-webkit-scrollbar {
  display: none;
}
.sidebar-menu-container {
  display: flex;
  flex-direction: column;
  /* margin-left: 6px;
  margin-right: 6px; */
  height: calc(100vh - 80px);
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 8;
}

.sidebar-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.sidebar-menu-item:hover {
  background-color: #801769;
  box-shadow: #801769 0px 2px 10px;
  border-radius: 4px;
  /* transform: scale3d(1.02, 1.02, 1.02); */
}
.sidebar-menu-selected {
  background-color: #801769;
  border-radius: 4px;
}

.sidebar-menu-item-icon {
  color: #ffffff;
  margin-right: 16px;
}

.sidebar-menu-item-para {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 12px 0px 12px 0px;
}

@media screen and (max-width: 740px) {
  /* .sidebar-menu-item-para {
    display: none;
  } */

  .sidebar-menu-container {
    margin-left: 10px;
    /* margin-right: 10px; */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sidebar-container {
    display: none;
  }
}
