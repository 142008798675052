.table-not-found-para1 {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  opacity: 30%;
  margin: 20px 0;
}

@media screen and (max-width: 900px) {
  .table-not-found-para1 {
    text-align: left;
    padding-left: 20px;
  }
}
