.login-page-container {
  display: flex;
  height: 100vh;
}

.login-form {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-side-img {
  object-fit: cover;
  height: auto;
  max-width: 100%;
}
.sideimage_container {
  background: white;
  /* opacity: 0.7%; */

  width: 100%;
}
.grand_color {
  background-image: url("../../asset/sideimg.png");
  width: 100%;
  height: 100%;
  position: relative;
}
.grand_color::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 0.1) 100%
  );
  /* opacity: 0.7; */
}

@media screen and (max-width: 856px) {
  .login-form {
    width: 90%;
    min-height: 100vh;
  }
  .login-page-container {
    justify-content: center;
    align-items: center;
  }
}

.login-form-logo {
  width: 135px;
  height: 31px;
  margin-bottom: 12px;
}

.login-input-field-container {
  width: 360px;
}

.forgot-input-field-container {
  width: 360px;
  margin-bottom: 15px;
}
@media screen and (max-width: 856px) {
  .login-input-field-container {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.login-heading-container {
  width: 80%;
  display: flex;
  justify-content: flex-start;
}
.login-heading {
  font-size: 28px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 8px;
}

.login-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #000000;
  margin: 0px;
  margin-bottom: 8px;
}

.login-show-password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  margin-bottom: 13px;
  margin-top: 13px;
}

@media screen and (max-width: 856px) {
  .login-show-password-container {
    max-width: 90%;
  }
}

.login-show-pass-checkbox {
  border: 1px solid #a9a9a9;
  outline: none;
  width: 14px;
  height: 16px;
  border-radius: 4px;
  margin-right: 7px;
  margin-left: 0px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.login-show-pass-checkbox:hover {
  /* transform: scale(1.2); */
}
.login-show-pass-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-show-pass-checkbox-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

.login-forgot-password-btn {
  background-color: transparent;
  width: 130px;

  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--primaryColor);
  border: 0px;
  cursor: pointer;
  outline: none;
  text-align: right;
  padding-right: 0px;
  transition: all 0.2s ease-in-out;
}
.login-forgot-password-btn:hover {
  /* transform: scale(1.05); */
}
@media screen and (max-width: 568px) {
  .login-show-pass-checkbox-label {
    font-size: 10px;
  }
  .login-forgot-password-btn {
    font-size: 10px;
  }
}

.login-page-btn {
  background-color: var(--primaryColor);
  width: 360px;
  height: 36px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: #ffffff;
  cursor: pointer;
  border: 0px;
  outline: none;
}

.login-company-info-para-container {
  align-self: flex-end;
}

.login-company-info-para1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0em;
  color: #000000;
  width: 252px;
  text-align: right;
  margin-left: 18px;
}

.login-company-info-para2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  color: var(--primaryColor);
  text-align: right;
}

.login-form::after {
  content: "";
  background-color: white;
}
@media screen and (max-width: 856px) {
  .login-company-info-container {
  }
  .sideimage_container {
    display: none;
  }
}

.login_button button {
  width: 360px;
}
@media screen and (max-width: 856px) {
  .login-page-btn {
    max-width: 90%;
  }

  .login-page-para-reg-container {
    width: 90%;
    flex-direction: column;
    gap: 0px;
  }
}

.login-page-reg-para {
  font-size: 14px;
  font-weight: 500;
}
