.dashboard_container {
  padding: 20px;
}
.filter_table_container {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filtertable_para {
  margin-right: 10px;
  font-weight: 600;
}
.filtertable_para span {
  margin-left: 10px;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 400;
  color: var(--primaryColor);
}

.sort_by_filter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.filter_input {
}
.filter_input .dropdown-container {
  margin-bottom: 0;
}

.filter_input .dropdown-container .dropdown-input {
  min-width: 120px;
  max-width: 134px;
  width: auto;
  height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}
@media screen and (max-width: 576px) {
  .filter_table_container {
    row-gap: 15px;
    flex-direction: column;
  }
}
